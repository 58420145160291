<template>
  <div>
    <fieldsTds
      :fieldAttrRow="fieldAttrRow"
      :fieldAttributes="{ ...fieldAttributes, type: 'text', value: localValue }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
      :value="localValue"
    ></fieldsTds>
  </div>
</template>
<script>
import fieldsTds from "@/commonComponents/fieldsTds.vue";
import { getfieldAttrRow } from "@/js/helper.js";
export default {
  components: { fieldsTds },
  props: ["result", "field", "fieldAttributes","value"],
  computed: {
    type() {
      return this.result.valueLine.c_type;
    },
    fieldAttrRow: function () {
      return getfieldAttrRow(this.getFieldsAttributes, this.field);
    },
    localValue() {
      let value = "";
      if (
        typeof this.value == "undefined" ||
        this.value == ""
      ) {
        value = this.result.additional.protocolsPorts[this.type];
      } else {
        value = this.value;
      }
      return value;
    },
  },
};
</script>